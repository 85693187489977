.nav_bar {
    width: 100%;
    height: 29px;
    display: flex;
}

.frameNav {
    display: flex;
    flex-direction: row;
    gap: 17px;
    align-items: center;
    width: 100%;
    height: 60px;
}

.links {
    margin-left: auto;
    margin-right: 80px;
    margin-top: 15px;
}

.link {
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    color: rgba(1, 1, 1, 1);
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 19px; */
    letter-spacing: 0em;
}

.link:hover {
    text-shadow: 0.2px 0.2px 0.2px #333;
}

.logo_se77e {
    margin-left: 180px;
    margin-top: 15px;
}

.logo-desktop {
    width: 60px;
    height: 60px;
}

.buttonAssign {
    border-radius: 50px;
    width: 120px;
    height: 48px;
    margin-left: 20px;

    background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, #E32285 28.23%, #9747FF 79.08%);
    border-radius: 50px;
    border: 3px solid transparent;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 19px;
    text-decoration: none;
    color: #010101;
    cursor: pointer;
    transition: width 0.3s;
}

.buttonAssign:hover {
    width: 140px;
}

.buttonLogin {
    border-radius: 50px;
    width: 210px;
    height: 48px;
    margin-left: 20px;

    background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, rgba(143, 192, 69, 1) 28.23%, rgba(41, 171, 227, 1) 79.08%);
    border-radius: 50px;
    border: 3px solid transparent;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 19px;
    text-decoration: none;
    color: #010101;
    cursor: pointer;
    transition: width 0.3s;
}

.buttonLogin:hover {
    width: 220px;
}

.bm-burger-button {
    position: absolute;
    width: 28px;
    height: 25px;
    left: 90%;
    top: 58px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: linear-gradient(269.52deg, #E32285 28.23%, #9747FF 79.08%), linear-gradient(269.52deg, #E32285 28.23%, #9747FF 79.08%);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    z-index: 1100;
    width: 300px;
    right: 0px !important;

    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: rgb(252, 252, 252);
    /* padding: 2.5em 1.5em 0; */
    padding-left: 15px;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 1000;
    height: 30%;

}

.menu_burger_mobile{
    display: none;
}

@media (max-width: 1440px) {
    .nav_bar {
        width: 100%;
        height: 15px;
        display: flex;
    }

    .frameNav {
        display: flex;
        flex-direction: row;
        gap: 17px;
        align-items: center;
        width: 100%;
        height: 45px;
    }

    .buttonAssign {
        border-radius: 50px;
        width: 100px;
        height: 42px;
        margin-left: 20px;

        background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, #E32285 28.23%, #9747FF 79.08%);
        border-radius: 50px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 15px;
        text-decoration: none;
        color: #010101;
        cursor: pointer;
        transition: width 0.3s;
    }

    .buttonAssign:hover {
        width: 115px;
    }

    .buttonLogin {
        border-radius: 50px;
        width: 180px;
        height: 42px;
        margin-left: 20px;

        background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, rgba(143, 192, 69, 1) 28.23%, rgba(41, 171, 227, 1) 79.08%);
        border-radius: 50px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 15px;
        text-decoration: none;
        color: #010101;
        cursor: pointer;
        transition: width 0.3s;
    }

    .buttonLogin:hover {
        width: 185px;
    }

    .logo-desktop {
        width: 45px;
        height: 45px;
    }

    .link {
        padding: 3px;
        margin-left: 5px;
        margin-right: 5px;
        text-decoration: none;
        color: rgba(1, 1, 1, 1);
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
    }
}

@media (max-width: 500px) {
    .nav_bar {
        width: 100%;
        height: 0px;
        display: flex;
    }

    .frameNav {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        width: 100%;
        height: 30px;
    }

    .buttonAssign {
        border-radius: 50px;
        width: 100px;
        height: 42px;
        margin-left: 0%;
        margin-top: 5px;
        float: left;

        background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, #E32285 28.23%, #9747FF 79.08%);
        border-radius: 50px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14.5px;
        line-height: 12px;
        text-decoration: none;
        color: #010101;
        cursor: pointer;
        transition: width 0.3s;
    }

    .buttonAssign:hover {
        width: 115px;
    }

    .buttonLogin {
        border-radius: 50px;
        width: 120px;
        height: 42px;
        margin-left: 5%;
        margin-top: 5px;
        float: right;

        background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, rgba(143, 192, 69, 1) 28.23%, rgba(41, 171, 227, 1) 79.08%);
        border-radius: 50px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14.5px;
        line-height: 12px;
        text-decoration: none;
        color: #010101;
        cursor: pointer;
        transition: width 0.3s;
    }

    .buttonLogin:hover {
        width: 125px;
    }

    .menu_burger_mobile{
        display: block;
    }

    .logo_se77e {
        margin-left: 50px;
        margin-top: 20px;
    }

    .logo-desktop {
        width: 40px;
        height: 40px;
    }

    .links-text {
        display: none;
    }

    .buttons-header {
        display: none;
    }

    .buttons-header-mobile {
        display: flex !important;
        justify-content: center;
        margin-top: 30px;
    }

    .link {
        padding: 3px;
        margin-top: 18px;
        text-decoration: none;
        color: rgba(1, 1, 1, 1);
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
    }

}