.dashboard {
    margin-top: 50px;
}

.dashboard-cards {
    padding-top: 100px;
    margin-left: 170px;
    margin-right: 170px;
}

.custom-input{
    background-color: transparent;
    color: #FFF;
    border: none;
}

.buttonEditAdmin {
    border-radius: 50px;
    width: 130px;
    height: 65px;
    background: linear-gradient(#494949, #333) padding-box, linear-gradient(180.52deg, rgba(143, 192, 69, 1) 30.23%, rgba(41, 171, 227, 1) 70.08%);
    border-radius: 50px;
    border: 2px solid transparent;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: width 0.3s;
}

.buttonEditAdmin:hover {
    width: 150px;
}

.date_picker {
    margin-left: auto;
    margin-right: 2%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width: 350px;
    height: 64px;
    border-radius: 20px;
    border: 1px solid var(--Branco, #FFF);
    background: var(--Linear, linear-gradient(326deg, rgba(255, 255, 255, 0.15) -23.11%, rgba(255, 255, 255, 0.00) 111.75%));
    /* modelo 1 */
    box-shadow: 0px 4px 49px 0px rgba(1, 1, 1, 0.12);
    backdrop-filter: blur(12.5px);

    color: #FFF;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.totalizer_1 {
    width: 95%;
    height: 64px;
    display: flex;
    align-items: center;

    border-radius: 20px;
    border: 1px solid var(--roxo--rosa, #E32285);

    background: var(--Linear, linear-gradient(326deg, rgba(255, 255, 255, 0.15) -23.11%, rgba(255, 255, 255, 0.00) 111.75%));
    /* modelo 1 */
    box-shadow: 0px 4px 49px 0px rgba(1, 1, 1, 0.12);
    backdrop-filter: blur(12.5px);
}

.totalizer_1_title {
    color: #FFF;
    margin-left: 5%;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.totalizer_1_result {
    color: #FFF;

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.icon-totalizer_1 {
    margin-left: auto;
    margin-right: 5%;
}

.icon-dashboard {
    margin-top: 5px;
    width: 50px;
    height: 50px;
}

.admin-welcome {
    display: flex;
    width: 95%;
    padding: 31.698px 32px 185px 31px;
    justify-content: left;
    align-items: center;

    border-radius: 20px;
    border: 1px solid var(--Branco, #FFF);
    background: var(--Linear, linear-gradient(326deg, rgba(255, 255, 255, 0.15) -23.11%, rgba(255, 255, 255, 0.00) 111.75%));
    /* modelo 1 */
    box-shadow: 0px 4px 49px 0px rgba(1, 1, 1, 0.12);
    backdrop-filter: blur(12.5px);

    color: #FFF;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.admin-social {
    display: flex;
    width: 98%;
    padding: 31.698px 32px 50px 31px;
    justify-content: left;
    align-items: center;

    border-radius: 20px;
    border: 1px solid var(--Branco, #FFF);
    background: var(--Linear, linear-gradient(326deg, rgba(255, 255, 255, 0.15) -23.11%, rgba(255, 255, 255, 0.00) 111.75%));
    /* modelo 1 */
    box-shadow: 0px 4px 49px 0px rgba(1, 1, 1, 0.12);
    backdrop-filter: blur(12.5px);

    color: #FFF;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.admin-others {
    display: flex;
    width: 100%;
    max-height: 300px;
    /* Defina a altura máxima para habilitar a rolagem */
    padding: 31.698px 5px 50px 31px;
    justify-content: left;
    align-items: center;
    border-radius: 15px;
    border: 1px solid var(--Branco, #FFF);
    background: var(--Linear, linear-gradient(326deg, rgba(255, 255, 255, 0.15) -23.11%, rgba(255, 255, 255, 0.00) 111.75%));
    box-shadow: 0px 4px 49px 0px rgba(1, 1, 1, 0.12);
    backdrop-filter: blur(12.5px);
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

/* Estilos para barra de rolagem em navegadores compatíveis com WebKit (Chrome, Safari, etc.) */
.scroll-wrapper {
    overflow-y: auto;
    max-height: 250px;
    /* Defina a altura máxima */
    border-radius: 20px;
    /* Mesmo raio de borda do admin-others */
    padding-bottom: 30px;
}

/* Estilos para a barra de rolagem */
.scroll-wrapper::-webkit-scrollbar {
    width: 5px;
    /* Largura da barra de rolagem */
}

.scroll-wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Cor do "ponteiro" da barra de rolagem */
    border-radius: 8px;
    /* Arredonda as extremidades do "ponteiro" */
}

.scroll-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
    /* Cor da área vazia ao redor da barra de rolagem */
}



.admin-name {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #FFF;

    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.admin-graph {
    width: 100%;
    height: 360px;
    justify-content: left;
    /* align-items: center; */
    padding: 31.698px 32px 50px 31px;

    border-radius: 20px;
    border: 1px solid var(--Branco, #FFF);
    background: var(--Linear, linear-gradient(326deg, rgba(255, 255, 255, 0.15) -23.11%, rgba(255, 255, 255, 0.00) 111.75%));
    backdrop-filter: blur(60px);

    color: #FFF;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.progress,
.progress-stacked {
    --bs-progress-height: 4px;
    --bs-progress-bar-color: #333;
    --bs-progress-bar-bg: rgba(143, 192, 69, 1);
    background-color: rgba(0, 0, 0, 1);
    margin-top: 5px;
}