.barraSuperior{

    width: 100%;
    height: 65px;

    background: #000000;
}

.frameMsg{
    display: flex;
    flex-direction: row;
    gap: 17px;

    width: 100%;
    height: 29px;
}


/* LIGUE OU MANDE UMA MENSAGEM */
.callMsg{
    display: flex;
    width: 100%;
    height: 29px;
    margin-top: 15px;
    margin-left: 80px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    /* identical to box height */

    color: #FFFFFF;
}

.numberMsg{
    font-size: 17px;
    margin-left: 5px;
    color: #8FC045;
}

.icons{
    color: white;
    margin-right: 80px;
    margin-top: 15px;
    width: 200px;
}

.icon{
    font-size: 33px;
    padding-left: 3px;
    padding-right: 3px;
}

.icon:hover{
    color: yellow;
    cursor: pointer;
}

.coloredFrame{

    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, #64328A 0%, #E32285 17.88%, #29ABE3 34.03%, #8FC045 50.17%, #E84339 66.84%, #FFD703 84.03%, #F9B041 100%);

}

@media (max-width: 1440px) {
    .barraSuperior{

        width: 100%;
        height: 45px;
    
        background: #000000;
    }

    .callMsg{
        width: 100%;
        height: 20px;
        margin-top: auto;
        margin-left: 80px;
    
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;
        /* identical to box height */
    
        color: #FFFFFF;
    }

    .numberMsg{
        font-size: 10px;
        color: #8FC045;
    }

    .icons{
        color: white;
        margin-right: 20px;
        margin-top: 8px;
        width: 200px;
    }
    
    .icon{
        font-size: 24px;
        padding-left: 3px;
        padding-right: 3px;
    }
}

@media (max-width: 500px) {

    .callMsg{
        display: block;
        width: 100%;
        height: 20px;
        margin-top: auto;
        margin-left: 8px;
    
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 28px;
        /* identical to box height */
    
        color: #FFFFFF;
    }

    .numberMsg{
        /* margin-top: -16px; */
        font-size: 8px;
        margin-left: 0px;
        color: #8FC045;
    }

    .message{
        margin-bottom: -16px;
    }

    .icons{
        color: white;
        margin-right: 5px;
        margin-top: 8px;
        width: 150px;
    }
    
    .icon{
        font-size: 22px;
        padding-left: 3px;
        padding-right: 3px;
    }
}