.resposnsive-image {
  margin-top: -6.6vh;
  margin-bottom: -7.3vh;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.modal-90w {
  min-width: 1500px;
}

.carousel-indicators {
  display: none;
}

.carousel_container-edit {
  margin-top: 50px;
  background-color: "#0a0a0a";
  margin-bottom: -10px;
}

/* .retangle-line {
  width: 710px;
  height: 1px;
  flex-shrink: 0;
  background: linear-gradient(90deg, #64328A 0%, #E32285 100%);
}

.retangle-line2 {
  width: 710px;
  height: 1px;
  flex-shrink: 0;
  background: linear-gradient(90deg, #F9B041 0%, #29ABE3 100%);
} */

.btn_apaga_foto {
  border-radius: 50%;
  border: 2px solid #ffbc93;
  padding: 5px;
  background: #FF6100;
  color: #fff;
  position: relative;
  top: 25px;
  right: 4px;
}

.btn_edita_foto {
  border-radius: 30%;
  padding: 4px 5px;
  background: transparent;
  color: #fff;
  position: relative;
  bottom: 45px;
  left: 42%;
  z-index: 9999;
}

.icon_image {
  font-size: 25px;
}

.icon-min {
  font-size: 30px;
}

.carregar_imagem {
  width: 100%;
  height: auto;
  border: 2px solid #ffbc93;
  border-radius: 10px;
  cursor: pointer;
}

.panel-card-img-edit {
  width: 100%;
  height: auto;
  opacity: 0.8;
  transition: width 0.3s;
}

@media (max-width: 1440px) {
  .icon-min {
    font-size: 25px;
  }

  .modal-90w {
    min-width: 1300px;
  }
}