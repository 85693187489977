/* .nav_bar {
    width: 100%;
    height: 29px;
    display: flex;
}

.frameNav {
    display: flex;
    flex-direction: row;
    gap: 17px;
    align-items: center;
    width: 100%;
    height: 60px;
}

.links {
    margin-left: auto;
    margin-right: 80px;
    margin-top: 15px;
}

.link {
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    color: rgba(1, 1, 1, 1);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
}

.link:hover {
    text-shadow: 0.2px 0.2px 0.2px #333;
}

.logo_se77e {
    margin-left: 180px;
    margin-top: 15px;
}

.logo-desktop {
    width: 60px;
    height: 60px;
}
*/
.buttonAssignAdmin {
    border-radius: 50px;
    width: 120px;
    height: 48px;
    margin-left: 20px;

    background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, rgba(143, 192, 69, 1) 28.23%, rgba(41, 171, 227, 1) 79.08%);
    border-radius: 50px;
    border: 3px solid transparent;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-decoration: none;
    color: #010101;
    cursor: pointer;
    transition: width 0.3s;
}

.buttonAssignAdmin:hover {
    width: 140px;
}

.user_title_admin {
    color: #010101;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 1440px) {
    .user_title_admin {
        color: #010101;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .buttonAssignAdmin {
        border-radius: 50px;
        width: 100px;
        height: 42px;
        margin-left: 20px;
    
        background: linear-gradient(white, white) padding-box, linear-gradient(269.52deg, rgba(143, 192, 69, 1) 28.23%, rgba(41, 171, 227, 1) 79.08%);
        border-radius: 50px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 15px;
        text-decoration: none;
        color: #010101;
        cursor: pointer;
        transition: width 0.3s;
    }
    .buttonAssignAdmin:hover {
        width: 115px;
    }
  }