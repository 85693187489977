.responsive-image {
  width: 100%;
  height: auto;
}

.content {
  background: linear-gradient(165.23deg, #4C4C4C -1.19%, #000000 100%);
  height: auto;
}

.content-sub {
  background-image: url("../../../public/assets/lines.png");
  background-repeat: no-repeat, repeat;
  background-size: cover;
  /* background-position: top; */
  width: 100%;
  height: 100%;
}

.plan-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.plan-title {
  z-index: 999;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 44px;
  color: #FFFFFF;
}

.plan-highlight {
  background: var(--roxo--rosa, linear-gradient(270deg, #E32285 28.23%, #9747FF 79.08%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: normal;
}

.plan-highlight2 {
  z-index: 999;
  background: var(--Laranja-Azul, linear-gradient(270deg, #29ABE3 28.23%, #F9B041 79.08%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: normal;
  font-size: 45px;
}

.plan-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  z-index: 999;
}

.plan-card {
  margin-left: 25px;
  margin-right: 25px;
  width: 350px;
  transition: width 0.3s;
  cursor: pointer;
}

.plan-card-vantagens {
  margin-left: 25px;
  margin-right: 25px;
  width: 350px;
}

.plan-card:hover {
  width: 360px;
}

.panel-card-img {
  width: 100%;
  height: auto;
  transition: width 0.3s;
}

.retangle-line {
  width: 710px;
  height: 1px;
  flex-shrink: 0;
  background: linear-gradient(90deg, #64328A 0%, #E32285 100%);
}

.retangle-line2 {
  width: 710px;
  height: 1px;
  flex-shrink: 0;
  background: linear-gradient(90deg, #F9B041 0%, #29ABE3 100%);
}

.bg-1 {
  position: absolute;
  width: 350px;
  height: 350px;
  left: -10px;
  top: 57.291666666666664vw;

  background: #9747FF;
  filter: blur(400px);
  z-index: 1;
}

.bg-2 {

  position: absolute;
  width: 350px;
  height: 350px;
  left: 6.302083333333333vw;
  top: 57.291666666666664vw;

  background: rgba(151, 71, 255, 0.5);
  filter: blur(400px);
  z-index: 1;
}

.bg-3 {
  position: absolute;
  width: 155px;
  height: 555px;
  left: 86.61458333333333vw;
  top: 78.95833333333333vw;

  background: #E32285;
  filter: blur(400px);
  z-index: 1;
}

.bg-4 {
  position: absolute;
  width: 555px;
  height: 555px;
  left: -10px;
  top: 127.08333333333334vw;

  background: #29ABE3;
  filter: blur(400px);
  z-index: 1;
}

.bg-5 {

  position: absolute;
  width: 455px;
  height: 655px;
  left: 60.27083333333333vw;
  top: 326.53128430296377vh;

  background: #F9B041;
  filter: blur(400px);
  z-index: 1;
}

@media (max-width: 1440px) {
  .plan-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    color: #FFFFFF;
  }

  .plan-card {
    margin-left: 25px;
    margin-right: 25px;
    width: 250px;
    transition: width 0.3s;
    cursor: pointer;
  }

  .plan-card:hover {
    width: 265px;
  }

  .plan-card-vantagens {
    margin-left: 25px;
    margin-right: 25px;
    width: 250px;
    transition: width 0.3s;
    cursor: pointer;
  }

  .plan-highlight2 {
    background: var(--Laranja-Azul, linear-gradient(270deg, #29ABE3 28.23%, #F9B041 79.08%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    line-height: normal;
    font-size: 38px;
  }
}

@media (max-width: 950px) {
  .bg-1 {
    position: absolute;
    width: 350px;
    height: 350px;
    left: -10px;
    top: 87.291666666666664vw;
  
    background: #9747FF;
    filter: blur(400px);
    z-index: 1;
  }
  
  .bg-2 {
  
    position: absolute;
    width: 350px;
    height: 350px;
    left: 6.302083333333333vw;
    top: 87.291666666666664vw;
  
    background: rgba(151, 71, 255, 0.5);
    filter: blur(400px);
    z-index: 1;
  }
  
  .bg-3 {
    position: absolute;
    width: 155px;
    height: 555px;
    left: 56.61458333333333vw;
    top: 78.95833333333333vw;
  
    background: #E32285;
    filter: blur(400px);
    z-index: 1;
  }
  
  .bg-4 {
    position: absolute;
    width: 555px;
    height: 555px;
    left: -10px;
    top: 157.08333333333334vw;
  
    background: #29ABE3;
    filter: blur(400px);
    z-index: 1;
  }
  
  .bg-5 {
  
    position: absolute;
    width: 255px;
    height: 655px;
    left: 10.27083333333333vw;
    top: 207.08333333333334vw;
  
    background: #F9B041;
    filter: blur(400px);
    z-index: 1;
  }
}

@media (max-width: 500px) {
  .plan-container {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .plan-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
  }

  .plan-cards {
    display: block;
    justify-content: initial;
    text-align: center;
    margin: 0 10px 15px;
    z-index: 999;
  }

  .plan-card {
    margin-top: 50px;
    width: 250px;
    transition: width 0.3s;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
  }

  .plan-card:hover {
    width: 265px;
  }

  .plan-card-vantagens {
    margin-top: 50px;
    width: 250px;
    transition: width 0.3s;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
  }

  .plan-highlight2 {
    background: var(--Laranja-Azul, linear-gradient(270deg, #29ABE3 28.23%, #F9B041 79.08%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    line-height: normal;
    font-size: 34px;
  }

  .retangle-line {
    width: 90%;
    height: 1px;
    flex-shrink: 0;
    background: linear-gradient(90deg, #64328A 0%, #E32285 100%);
  }

  .retangle-line2 {
    width: 90%;
    height: 1px;
    flex-shrink: 0;
    background: linear-gradient(90deg, #F9B041 0%, #29ABE3 100%);
  }

  .bg-1 {
    position: absolute;
    width: 150px;
    height: 450px;
    left: -10px;
    top: 55.291666666666664vh;
  
    background: #9747FF;
    filter: blur(250px);
    z-index: 1;
  }
  
  .bg-2 {
  
    position: absolute;
    width: 150px;
    height: 450px;
    left: 6.302083333333333vw;
    top: 55.291666666666664vh;
  
    background: rgba(151, 71, 255, 0.5);
    filter: blur(150px);
    z-index: 1;
  }
  
  .bg-3 {
    position: absolute;
    width: 150px;
    height: 450px;
    left: 60%;
    top: 145.95833333333333vh;
  
    background: #E32285;
    filter: blur(300px);
    z-index: 1;
  }
  
  .bg-4 {
    position: absolute;
    width: 150px;
    height: 850px;
    left: -10px;
    top: 205.08333333333334vh;
  
    background: #29ABE3;
    filter: blur(300px);
    z-index: 1;
  }
  
  .bg-5 {
  
    position: absolute;
    width: 150px;
    height: 900px;
    right: 0;
    top: 395.53128430296377vh;
  
    background: #F9B041;
    filter: blur(300px);
    z-index: 1;
  }
}

@media (max-width: 500px) {
  .bg-3 {
    display: none;
  }
}