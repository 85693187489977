.se77e-logo {
    width: 3.125vw;
    height: 6.586169045005488vh;
}

.marketing1 {
    color: #999;

    font-family: Inter;
    font-size: 1.646542261251372vh;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
}

.icons-footer {
    color: white;
    margin-top: 15px;
    width: 200px;
}

.footer-title {
    color: var(--White, #FFF);
    font-family: Poppins;
    font-size: 1.756311745334797vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.footer-subtitle {
    color: #999;
    font-family: Poppins;
    font-size: 1.646542261251372vh;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    text-decoration: none;
}

.footer-subtitle:hover {
    color: #d8d8d8;
    cursor: pointer;
}

.footer-subtitle2 {
    color: #999;
    font-family: Poppins;
    font-size: 1.646542261251372vh;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    text-decoration: none;
}

.footer-retangle-line {
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background: linear-gradient(90deg, #64328A 0%, #E32285 17.88%, #29ABE3 34.03%, #8FC045 50.17%, #E84339 66.84%, #FFD703 84.03%, #F9B041 100%);
}

.rights-reserved {
    color: #FFF;
    font-family: Inter;
    font-size: 1.756311745334797vh;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.desktop {
    display: flex;
}

.mobile {
    display: none;
}

.tooltip-construction {
    position: absolute;
    margin-top: -5px;
    margin-left: 2px;
    background: #222222;
    border-radius: 3px;
    padding: 1px 5px;
    color: #999;
    font-size: 1.3vh;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.tooltip-construction.show-tooltip {
    opacity: 1; /* Altera a opacidade para 1 quando a classe show-tooltip estiver presente */
}

@media (max-width: 500px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }

    .se77e-logo {
        width: 50px;
        height: 50px;
    }
    .footer-subtitle2 {
        color: #999;
        font-family: Poppins;
        font-size: 1.20vh;
        font-style: normal;
        font-weight: 400;
        line-height: 17.5px;
        text-decoration: none;
    }
}