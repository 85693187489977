.privacy-policy-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.privacy-policy-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1a73e8;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: #4a90e2;
}

p {
  margin-bottom: 1rem;
}

ul {
  margin-left: 20px;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.5rem;
}
